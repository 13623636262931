<template>
<div>
    <div v-if="data.documentos_deudor">
    <b>Documentación presentada deudor</b>
    <v-simple-table dense>
        <thead>
            <tr>
                <th style="width:50px">No.</th>
                <th>Documento requerido</th>
                <th class="text-center">Estado</th>
            </tr>
        </thead>
        <tbody>
          <template  v-for="(doc, index) in data.documentos_deudor.docs">
            <tr v-if="doc.file">
                <td>{{ index +1}}</td>
                <td>{{doc.name}}</td>
                <td class="text-center"> 
                <a v-bind:href="'https://app.serprogreso.com'+doc.file" target="_blank" >Ver</a>
                </td>
            </tr>
          </template>
        </tbody>
    </v-simple-table>
    </div>
    <div v-if="data.documentos_codeudor">
    <b>Documentación presentada codeudor</b>
    <v-simple-table dense>
        <thead>
            <tr>
                <th style="width:50px">No.</th>
                <th>Documento requerido</th>
                <th class="text-center">Estado</th>
            </tr>
        </thead>
        <tbody>
          <template v-for="(doc, index) in data.documentos_codeudor.docs">
            <tr v-if="doc.file" >
                <td>{{ index +1}}</td>
                <td>{{doc.name}}</td>
                <td class="text-center"> 
                 <a v-bind:href="'https://app.serprogreso.com'+doc.file" target="_blank" >Ver</a>
                </td>
            </tr>
            </template>
        </tbody>
    </v-simple-table>
    </div>
    
    <div v-if="data.documentos_adjuntos && data.documentos_adjuntos.length > 0">
      <br>
      <b>Otros Documentos</b>
      <v-simple-table dense>
        <thead>
            <tr>
                <th style="width:50px">No.</th>
                <th>Documento</th>
                <th class="text-center"></th>
            </tr>
        </thead>
        <tbody>
          <template v-for="(doc, index) in data.documentos_adjuntos">
            <tr v-if="doc.path">
                <td>{{ index +1}}</td>
                <td>{{doc.name}}</td>
                <td class="text-center"> 
                <a v-bind:href="'https://app.serprogreso.com'+doc.path" target="_blank" >Ver</a>
             
                </td>
            </tr>
            </template>
        </tbody>
      </v-simple-table>
    </div>
    </div>

</template>

<script>

  export default {
    name: 'NgInfoDocumentos',
    data () {
      return {

      }
    },
    props: ['data']
   
  }
</script>
