<template>
<div v-if="data">
  <b>Información Persona Natural</b>
  <v-simple-table dense>
      <tbody>
        <tr>
          <td>Personas a cargo:</td><td>{{data.spn_personas_a_cargo}}</td>
        </tr>
        <tr>
          <td>Número de hijos:</td><td>{{data.spn_numero_hijos}}</td>
        </tr>
        <tr>
          <td>Gastos Familiares:</td>
          <td>{{$filters.currency(data.spn_gastos_familiares)}}</td>
        </tr>
        <tr>  
          <td >Tipo de Vivienda:</td>
          <td v-if="data.bus_tipo_vivienda_id">{{data.bus_tipo_vivienda_id.name}}</td>
        </tr>
        <tr>
          <td>Valor Arriendo:</td><td>{{$filters.currency(data.spn_valor_arriendo)}}</td>
        </tr>
        <tr>  
          <td>Fecha de ingreso a la empresa:</td><td>{{$filters.date(data.spn_fecha_ingreso_empresa)}}</td>
        </tr>
        <tr >
          <td >Tipo de contrato:</td>
          <td v-if="data.bus_tipo_contrato_laboral_id">{{data.bus_tipo_contrato_laboral_id.name}}</td>
        </tr>
        <tr>  
          <td >Tipo de salario:</td>
          <td v-if="data.bus_tipo_salario_id">{{data.bus_tipo_salario_id.name}}</td>
        </tr>
      </tbody>
  </v-simple-table> 
</div>

</template>

<script>

  export default {
    name: 'NgInfoPersonaNatural',
    data () {
      return {

      }
    },
    props: ['data']
   
  }
</script>
