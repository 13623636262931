<template>
 <div v-if="data.spn_activos_pasivos">
        <b>Activos y Pasivos</b>
      <v-simple-table dense v-if="data.spn_activos_pasivos.properties && data.spn_activos_pasivos.properties.length > 0">
         <thead>
            <tr><th class="text-center" colspan="6"> <b>Propiedades </b></th></tr>
            <tr>
              <th class="text-center">Tipo de inmueble</th>
              <th class="text-center">Dirección</th>
              <th class="text-center">Ciudad/Departamento</th>
              <th class="text-center">Hipotecado a</th>
              <th class="text-center">Valor Comercial</th>
            </tr>
          </thead>
          <tbody >
            <tr :key="item.id" v-for="(item, index) in data.spn_activos_pasivos.properties"  >
              <td>{{item.type_inmueble}}</td>
              <td class="text-center">{{item.address}}</td>
              <td class="text-center"> {{item.city}}</td>
              <td class="text-center">{{item.hipoteca}}</td>
              <td class="text-center">{{$filters.currency(item.price)}}</td>
            </tr>
          </tbody>
      </v-simple-table>
   <br>
      <v-simple-table dense v-if="data.spn_activos_pasivos.vehicles && data.spn_activos_pasivos.vehicles.length > 0">
          <thead dark>
                  <tr>
                    <th class="text-center" colspan="8">
                     <b>Vehículos </b>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-center">Tipo de Vehiculo</th>
                    <th class="text-center">Marca</th>  <th class="text-center">Línea</th>  
                    <th class="text-center">Modelo</th> <th class="text-center">Placa</th>
                    <th class="text-center">Pignorado a</th> <th class="text-center">Valor Comercial</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="item.id" v-for="(item, index) in data.spn_activos_pasivos.vehicles">
                    <td>{{item.type_vehiculo}}</td>
                    <td class="text-center">{{item.brand}}</td>
                    <td class="text-center"> {{item.line}}</td>
                    <td class="text-center">{{item.model}}</td>
                    <td class="text-center">{{item.plate}}</td>
                    <td class="text-center">{{item.pignorado}}</td>
                    <td class="text-center">{{$filters.currency(item.price)}}</td>
  
                  </tr>
                </tbody>
      </v-simple-table>
      <br>
      <v-simple-table dense v-if="data.spn_activos_pasivos.financials && data.spn_activos_pasivos.financials.length > 0">
            <thead>
              <tr><th class="text-center" colspan="6"><b>Productos Financieros </b> </th></tr>
              <tr>
                <th class="text-center">Tipo de Producto</th>
                <th class="text-center">Entidad</th>  <th class="text-center">Valor Inicial</th>  
                <th class="text-center">Saldo Actual</th> <th class="text-center">Valor de la Cuota Mensual</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="item.id" v-for="(item, index) in data.spn_activos_pasivos.financials" >
                <td>{{item.type_product}}</td>
                <td class="text-center">{{item.entity}}</td>
                <td class="text-center"> {{$filters.currency(item.initial_value)}}</td>
                <td class="text-center">{{$filters.currency(item.saldo)}}</td>
                <td class="text-center">{{$filters.currency(item.mensual_value)}}</td>
              </tr>
            </tbody>
      </v-simple-table>
      </div>

</template>

<script>

  export default {
    name: 'NgInfoActivosPasivos',
    props: ['data']
   
  }
</script>
