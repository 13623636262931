<template>
<div style="width: 100%" v-if="data">
   
   <!--div>
    <info-trace-credit :items="data.trace"></info-trace-credit>
   </div-->
   
   <h3 v-if="data.credito_reestructurado_id" class="my-2" style="background-color: gold;
    padding: 5px;"> {{data.tipo_reestructuracion}} de crédito: {{data.credito_reestructurado_id}}</h3>
   
  <b>Deudor:</b>
  <ul v-if="data.cliente">
      <li ><b>Nombre:</b>  {{data.cliente.nombre}} {{data.cliente.apellido}}</li>
      <li><b>Documento de Identidad:</b>  {{data.cliente.documento}}</li>
      <li v-if="data.cliente.ocupacion"><b>Ocupación:</b>  {{data.cliente.ocupacion.name}}</li>
      <li v-if="data.infoFinanDeudor"><b>Ingresos totales:</b>  {{$filters.currency(data.infoFinanDeudor.total_ingresos)}}</li>
  </ul>
  <div v-if="data.codeudor && data.codeudor.nombre">
  <b>Codeudor:</b>
  <ul v-if="data.codeudor">
      <li ><b>Nombre:</b>  {{data.codeudor.nombre}} {{data.codeudor.apellido}}</li>
      <li><b>Documento de Identidad:</b>  {{data.codeudor.documento}}</li>
      <li  v-if="data.codeudor.ocupacion"><b>Ocupación:</b>  {{data.codeudor.ocupacion.name}}</li>
      <li v-if="data.infoFinanCodeudor"><b>Ingresos totales:</b>  {{$filters.currency(data.infoFinanCodeudor.total_ingresos)}}</li>
  </ul>
  </div>
  <b>Solicitud y condiciones de crédito:</b>
  <ul v-if="data.linea">
      <li><b>No. de solicitud:</b>  {{data.id}}</li>
      <li><b>Línea de crédito:</b>  {{data.linea.name}}</li>
      <li v-if="data.producto"><b>Producto:</b>  {{data.producto.name}}</li>
      <li><b>Valor solicitado:</b>  {{$filters.currency(data.monto)}}</li>
      <li><b>Plazo:</b>  {{data.plazo}}</li>
      <li><b>Día de corte:</b>  {{data.fecha_corte}}</li>
      <li><b>Tasa de interés (N.M.V.):</b>  {{data.tasa_temporal}}</li>
  </ul>
  

<div v-if="data" >
 <div v-if="data.cliente && data.cliente.bus_tipo_cliente_id == 'natural'">
    <NgInfoPersonaNatural :data="data" class="mt-4"></NgInfoPersonaNatural>
  </div>
 <br>
    <div>
    <NgInfoActivosPasivos :data="data"></NgInfoActivosPasivos>
  </div>
  <br>
 
  <div v-if="data && data.referencias">
    <NgInfoReferencias :data="data"></NgInfoReferencias>
  </div>
    <br>
  <div>
   <NgInfoDocumentos :data="data"></NgInfoDocumentos>
  </div>
</div>
</div>

</div>

</template>
<script>
import NgInfoPersonaNatural from "./NgInfoPersonaNatural";
import NgInfoActivosPasivos from "./NgInfoActivosPasivos";
import NgInfoReferencias from "./NgInfoReferencias";
import NgInfoDocumentos from "./NgInfoDocumentos";

export default {
  name: "InfoSolicitud",
  components: {
    NgInfoPersonaNatural,
    NgInfoActivosPasivos,
    NgInfoReferencias,
    NgInfoDocumentos,
  },
  data() {
    return {};
  },
  props: ["data"],
};
</script>
