<template>
<div v-if="data">
    <b>Referencias</b>
         <v-simple-table dense  v-if="data.referencias.family && data.referencias.family.length > 0">
         <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center" colspan="7">Referencias Familiares</th>
            </tr>
            <tr>
              <th class="text-center">Nombres y Apellidos</th>
              <th class="text-center">Ocupación</th>
              <th class="text-center">Parentesco</th>
              <th class="text-center">Dirección</th>
              <th class="text-center">Teléfono</th>
              <th class="text-center">Ciudad/Departamento</th>
            </tr>
          </thead>
          <tbody >
            <tr v-for="(item, index) in data.referencias.family">
              <td>{{item.name}}</td><td class="text-center">{{item.ocupacion}}</td><td class="text-center"> {{item.parentesco}}</td>
              <td class="text-center">{{item.address}}</td><td class="text-center">{{item.phone}}</td>
              <td class="text-center">{{item.city}}</td>
            </tr>
          </tbody>
          </template>
      </v-simple-table>
      <br>
       <v-simple-table dense  v-if="data.referencias.personal  && data.referencias.personal.length > 0" >
              <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center" colspan="6">Referencias Personales</th>
            </tr>
            <tr>
              <th class="text-center">Nombres y Apellidos</th>
              <th class="text-center">Ocupación</th>
              <th class="text-center">Dirección</th>
              <th class="text-center">Teléfono</th>
              <th class="text-center">Ciudad/Departamento</th>
            </tr>
          </thead>
          <tbody >
            <tr :key="item.id" v-for="(item, index) in data.referencias.personal" >
              <td>{{item.name}}</td><td class="text-center">{{item.ocupacion}}</td>
              <td class="text-center">{{item.address}}</td><td class="text-center">{{item.phone}}</td>
              <td class="text-center">{{item.city}}</td>
            </tr>
          </tbody>
          </template>
      </v-simple-table>
      <br>
      <v-simple-table dense  v-if="data.referencias.comercial && data.referencias.comercial.length > 0">
          <thead>
            <tr>
              <th class="text-center" colspan="6">Referencias Comerciales </th>
            </tr>
            <tr>
              <th class="text-center">Nombres y Apellidos</th>
              <th class="text-center">Ocupación</th>
              <th class="text-center">Dirección</th>
              <th class="text-center">Teléfono</th>
              <th class="text-center">Ciudad/Departamento</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="item.id" v-for="(item, index) in data.referencias.comercial" >
              <td>{{item.name}}</td><td class="text-center">{{item.ocupacion}}</td>
              <td class="text-center">{{item.address}}</td><td class="text-center">{{item.phone}}</td>
              <td class="text-center">{{item.city}}</td>
            </tr>
          </tbody>
      </v-simple-table>
      </div>

</template>

<script>

  export default {
    name: 'NgInfoReferencias',
    data () {
      return {

      }
    },
    props: ['data']
   
  }
</script>
