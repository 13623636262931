<template>
<div class="small-text" style="height: 400px;overflow: auto;">
      <v-simple-table dense v-if="item && item.plan_de_pago">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Cuota</th>
              <th class="text-left">Fecha corte</th>
              <th class="text-left">Fecha limite</th>
              <th class="text-right">Cuota fija</th>
              <th class="text-right">Saldo a capital</th>
              <th class="text-right">Interes corriente</th>
              <th class="text-right">Ajuste de interes</th>
               <th class="text-right">Aporte a capital</th>
              <template v-for="c in item.plan_de_pago[0].conceptos">
                <th class="text-right">{{conceptos[c.id].name}}</th>
              </template>
             
              <th class="text-right">Total Cuota</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cuota in item.plan_de_pago" :key="cuota.cuota">
              <td>{{ cuota.cuota }}</td>
              <td>{{ cuota.f_corte }}</td>
              <td>{{ cuota.f_limite }}</td>
              <td class="text-right">{{ $filters.currency(cuota.cuota_fija) }}</td>
              <td class="text-right">{{ $filters.currency((cuota.saldo_capital-cuota.aporte_capital)) }}</td>
              <td class="text-right">{{ $filters.currency(cuota.intereses) }}</td>
              <td class="text-right">{{ $filters.currency(cuota.ajuste_interes) }}</td>
              <td class="text-right">{{ $filters.currency(cuota.aporte_capital) }}</td>
              <template v-for="c in item.plan_de_pago[0].conceptos">
                <td class="text-right">{{$filters.currency(c.total)}}</td>
              </template>
              
              <th class="text-right">{{ $filters.currency(cuota.total) }}
              </th>
            </tr>
            <tr v-if="item && totales" >
              <td></td>
              <td></td>
              <td>Totales:</td>
              <td class="text-right">{{$filters.currency(totales.cuota_fija)}}</td>
              <td class="text-right">{{$filters.currency(0)}}</td>
              <td class="text-right">{{$filters.currency(totales.intereses)}}</td>
              <td class="text-right">{{$filters.currency(totales.ajuste_interes)}} </td>
              <td class="text-right">{{$filters.currency(totales.aporte_capital)}}</td>
              <template v-for="c in item.plan_de_pago[0].conceptos">
                <td class="text-right">{{$filters.currency(totales[c.id])}}</td>
              </template>
              
              <th class="text-right">{{$filters.currency(totales.total)}} </th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

</template>

<script>

  export default {
    name: 'PlanPagos',
    data () {
      return {
        reloadKey: 1,
        item: null,
        conceptos: {},
        totales: {}
      }
    },
    props: ['solicitud'],
    mounted(){
       this.loadItem();
    },
    methods: {
      loadItem(){
        this.$http.get( 'api/admin/solicitudes/plan-pagos/'+this.solicitud )
        .then(res => {
            //console.log(res.data);
            if(res.data.success){
              //console.log(res.data)
              this.item = res.data.item;
              
              for(const c of res.data.conceptos){
                this.conceptos[c.id] = c;
              }
              //console.log(this.conceptos)
              this.calcularTotales();
              this.reloadKey++;
            }
        }).catch(err => {
          console.log(err);
        });
      },
      calcularTotales(){
        if(this.item.plan_de_pago){
          for(const pl of this.item.plan_de_pago){
           // console.log(pl)
            for(let i in pl){
              if(i == 'conceptos'){
                for(const c of pl.conceptos){
                  if(!this.totales[c.id]){
                    this.totales[c.id] = 0;
                  }
                  this.totales[c.id] += parseFloat(c.total);
                }
              }else{
                if(!this.totales[i]){
                  this.totales[i] = 0;
                }
                this.totales[i] += parseFloat(pl[i]);
              }
            }
          }
          //console.log(this.totales)
        }
      }
    },      
  }
</script>
