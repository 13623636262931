<template>
 <div class="small-text">  
      <v-data-table v-if="items" mobile-breakpoint="200"
        :headers="headers"
        :items="items"
        :single-expand="singleExpand"
        
        item-key="id"
        class="elevation-1"
        hide-default-footer
        :disable-pagination="true"
      >
 <template v-slot:item.fecha_corte="{ item }">
      {{ $filters.date(item.fecha_corte)}}
      </template>
      <template v-slot:item.fecha_limite_pago="{ item }">
      {{ $filters.date(item.fecha_limite_pago)}}
      </template>
       <template v-slot:item.saldo_capital_final="{ item }">
      {{ $filters.currency(item.saldo_capital_final)}}
      </template>
      
      <template v-slot:item.interes="{ item }">
      {{ $filters.currency(item.interes)}}<br>
      {{ $filters.currency(item.interes_pagado)}}<br>
      <b>{{ $filters.currency(item.interes_xpagar)}}</b>
      </template>
      <template v-slot:item.ajuste_interes="{ item }">
      {{ $filters.currency(item.ajuste_interes)}}<br>
      {{ $filters.currency(item.ajuste_interes_pagado)}}<br>
      <b>{{ $filters.currency(item.ajuste_interes_xpagar)}}</b>
      </template>
      <template v-slot:item.mora="{ item }">
      {{ $filters.currency(item.mora)}}<br>
      {{ $filters.currency(item.mora_pagada)}}<br>
      <b>{{ $filters.currency(item.mora_xpagar)}}</b>
      
      </template>
      
      <template v-slot:item.estado_cuota="{ item }">
       <b v-if="item.estado_cuota == 0">No Gen</b>
       <b v-else-if="item.estado_cuota == 1">Gen</b>
       <b v-else-if="item.estado_cuota == 2">Abonada</b>
       <b v-else-if="item.estado_cuota == 3">Pagada</b>
      
      </template>
      <template v-slot:item.aporte_capital="{ item }">
      {{ $filters.currency(item.aporte_capital)}}<br>
      {{ $filters.currency(item.aporte_capital_pagado)}}<br>
      <b>{{ $filters.currency(item.aporte_capital_xpagar)}}</b>
      </template>
      
      <template v-slot:item.cobranza="{ item }">
      {{ $filters.currency(item.cobranza)}}<br>
      {{ $filters.currency(item.cobranza_pagada)}}<br>
      <b>{{ $filters.currency(item.cobranza_xpagar)}}</b>
      </template>
      <template v-slot:item.total_cuota="{ item }">
      {{ $filters.currency(item.total_cuota)}}
      </template>
   
      
      <template v-slot:item.otros_cargos_1="{ item }">
      {{ $filters.currency(item.otros_cargos_1)}}<br>
      {{ $filters.currency(item.otros_cargos_1_pagado)}}<br>
      <b>{{ $filters.currency(item.otros_cargos_1_xpagar)}}</b>
      </template>
      <template v-slot:item.otros_cargos_2="{ item }">
      {{ $filters.currency(item.otros_cargos_2)}}
      {{ $filters.currency(item.otros_cargos_2_pagado)}}<br>
      <b>{{ $filters.currency(item.otros_cargos_2_xpagar)}}</b>
      </template>
      
      <template v-slot:item.otros_cargos_3="{ item }">
      {{ $filters.currency(item.otros_cargos_3)}}<br>
      {{ $filters.currency(item.otros_cargos_3_pagado)}}<br>
      <b>{{ $filters.currency(item.otros_cargos_3_xpagar)}}</b>
      </template>
      <template v-slot:item.otros_cargos_4="{ item }">
      {{ $filters.currency(item.otros_cargos_4)}}<br>
      {{ $filters.currency(item.otros_cargos_4_pagado)}}<br>
      <b>{{ $filters.currency(item.otros_cargos_4_xpagar)}}</b>
      </template>
      
      <template v-slot:item.otros_cargos_5="{ item }">
      {{ $filters.currency(item.otros_cargos_5)}}<br>
      {{ $filters.currency(item.otros_cargos_5_pagado)}}<br>
      <b>{{ $filters.currency(item.otros_cargos_5_xpagar)}}</b>
      </template>
      <template v-slot:item.otros_cargos_6="{ item }">
      {{ $filters.currency(item.otros_cargos_6)}}<br>
      {{ $filters.currency(item.otros_cargos_6_pagado)}}<br>
      <b>{{ $filters.currency(item.otros_cargos_6_xpagar)}}</b>
      </template>
      
      <template v-slot:item.otros_cargos_7="{ item }">
      {{ $filters.currency(item.otros_cargos_7)}}<br>
      {{ $filters.currency(item.otros_cargos_7_pagado)}}<br>
      <b>{{ $filters.currency(item.otros_cargos_7_xpagar)}}</b>
      </template>
      <template v-slot:item.otros_cargos_8="{ item }">
      {{ $filters.currency(item.otros_cargos_8)}}<br>
      {{ $filters.currency(item.otros_cargos_8_pagado)}}<br>
      <b>{{ $filters.currency(item.otros_cargos_8_xpagar)}}</b>
      </template>
      
        <!--template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" style="padding: 15px" v-if="item.movimientos_diarios">
          <div style="height: 200px;overflow:auto; width:80%">
             <table style="position: relative;width:100%" class="table-bordered" >
               <thead>
               <tr>
                 <th style="position: sticky;top: 0;">Días</th>
                 <th style="position: sticky;top: 0;width:80px">Fecha</th>
                 <th style="position: sticky;top: 0;">Interes corriente</th>
                 <th style="position: sticky;top: 0;">Ajuste Interes</th>
                 <th style="position: sticky;top: 0;">Interes Mora</th>
                 <th style="position: sticky;top: 0;">Cobranza</th>
               </tr>
               </thead>
               <tbody>
                <template v-for="(m, key, index) in item.movimientos_diarios">
                
               <tr>
                 <td>{{index+1}}</td><td> {{key}}</td>
                 <td style="padding-left:15px"><span v-if="m.interes">{{$filters.currency(m.interes)}}</span></td>
                 <td style="padding-left:15px"><span v-if="m.ajuste_interes">{{$filters.currency(m.ajuste_interes)}}</span></td>
                 <td style="padding-left:15px"><span v-if="m.mora">{{$filters.currency(m.mora)}}</span></td>
                 <td style="padding-left:15px"><span v-if="m.cobranza">{{$filters.currency(m.cobranza)}}</span></td>
                </tr>
                <tr v-if="m.pago">
                 <td>{{index+1}}</td><td> {{key}}</td>
                 <td colspan="6">
                  {{m.pago}}
                  {{m.distribucion}}
                 </td>
                </tr>
                
                 </template>
                 

               </tbody>
            </table>
            </div>
          </td>
        </template-->
      </v-data-table>
    </div>

</template>

<script>

  export default {
    name: 'CuotasCredito',
    data () {
      return {
            reloadKey: 1,
            items: null,
            conceptos: {},
            expanded: [],
            singleExpand: true,
            headers: [],
            totales: {}
      }
    },
    props: ['solicitud'],
    mounted(){
       this.loadItem();
    },
    methods: {
      loadItem(){
 
        this.$http.get( 'api/admin/solicitudes/cuotas-credito/'+this.solicitud )
        .then(res => {
            //console.log(res.data);
            if(res.data.success){
              //console.log(res.data)
              for(const c of res.data.conceptos){
                this.conceptos[c.id] = c;
              }
             const headers = [
               { text: '', value: 'data-table-expand', width: '45px' },
                { text: '#', align: 'start', sortable: true, value: 'cuota' },
                { text: 'F. corte',  align: 'start',  sortable: true, value: 'fecha_corte' },
                { text: 'F. limite', align: 'start', sortable: true, value: 'fecha_limite_pago' },
                //{ text: 'Cuota Fija', align: 'start', sortable: true, value: 'cuota_fija'},
                { text: 'Saldo a capital', align: 'start', sortable: true, value: 'saldo_capital_final'},
                { text: 'Aporte Capital', align: 'start', sortable: true, value: 'aporte_capital' },
                { text: 'Interes corriente', align: 'start', sortable: true,  value: 'interes' },
                //{ text: 'IxPagar', align: 'start', sortable: true,  value: 'interes_xpagar' },
                { text: 'Ajuste interes', align: 'start', sortable: true,  value: 'ajuste_interes' },
                { text: 'Mora', align: 'start', sortable: true, value: 'mora' },
                { text: 'Días Mora', align: 'start', sortable: true, value: 'dias_sin_pagar' }
                //{ text: 'MxPagar', align: 'start', sortable: true, value: 'mora_xpagar' }
              ]
              const items = res.data.items;
              this.$emit('event:cuotas', items);
              if(items && items.length>0){
                const item = items[0];
                if(item.otros_cargos && item.otros_cargos.length > 0){
                  for(const c of item.otros_cargos){
                    headers.push({ text: this.conceptos[c.id].name, align: 'start', sortable: true, value: 'otros_cargos_'+c.id });
                  }
                }
              }
              headers.push({ text: 'Cobranza', align: 'start', sortable: true, value: 'cobranza' });
              //headers.push({ text: 'Aporte Capital', align: 'start', sortable: true, value: 'aporte_capital' });
              headers.push({ text: 'Total', align: 'start', sortable: true, value: 'total_cuota' });
              headers.push({ text: 'Estado', align: 'start', sortable: true, value: 'estado_cuota' });
              
              
              items.push(res.data.totales);
              
              this.$emit('changeTotals', { totales: JSON.parse(JSON.stringify(res.data.totales)),  headers})
              
              this.headers = headers;
              this.items = items;
              this.reloadKey++;
            }
        }).catch(err => {
          console.log(err);
        });
      }
    },      
  }
</script>
