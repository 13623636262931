var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"small-text"},[(_vm.items)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":"200","headers":_vm.headers,"items":_vm.items,"single-expand":_vm.singleExpand,"item-key":"id","hide-default-footer":"","disable-pagination":true},scopedSlots:_vm._u([{key:"item.fecha_corte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.date(item.fecha_corte))+" ")]}},{key:"item.fecha_limite_pago",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.date(item.fecha_limite_pago))+" ")]}},{key:"item.saldo_capital_final",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.saldo_capital_final))+" ")]}},{key:"item.interes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.interes))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.interes_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.interes_xpagar)))])]}},{key:"item.ajuste_interes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.ajuste_interes))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.ajuste_interes_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.ajuste_interes_xpagar)))])]}},{key:"item.mora",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.mora))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.mora_pagada))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.mora_xpagar)))])]}},{key:"item.estado_cuota",fn:function(ref){
var item = ref.item;
return [(item.estado_cuota == 0)?_c('b',[_vm._v("No Gen")]):(item.estado_cuota == 1)?_c('b',[_vm._v("Gen")]):(item.estado_cuota == 2)?_c('b',[_vm._v("Abonada")]):(item.estado_cuota == 3)?_c('b',[_vm._v("Pagada")]):_vm._e()]}},{key:"item.aporte_capital",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.aporte_capital))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.aporte_capital_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.aporte_capital_xpagar)))])]}},{key:"item.cobranza",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.cobranza))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.cobranza_pagada))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.cobranza_xpagar)))])]}},{key:"item.total_cuota",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.total_cuota))+" ")]}},{key:"item.otros_cargos_1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_1))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_1_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.otros_cargos_1_xpagar)))])]}},{key:"item.otros_cargos_2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_2))+" "+_vm._s(_vm.$filters.currency(item.otros_cargos_2_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.otros_cargos_2_xpagar)))])]}},{key:"item.otros_cargos_3",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_3))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_3_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.otros_cargos_3_xpagar)))])]}},{key:"item.otros_cargos_4",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_4))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_4_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.otros_cargos_4_xpagar)))])]}},{key:"item.otros_cargos_5",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_5))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_5_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.otros_cargos_5_xpagar)))])]}},{key:"item.otros_cargos_6",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_6))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_6_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.otros_cargos_6_xpagar)))])]}},{key:"item.otros_cargos_7",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_7))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_7_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.otros_cargos_7_xpagar)))])]}},{key:"item.otros_cargos_8",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_8))),_c('br'),_vm._v(" "+_vm._s(_vm.$filters.currency(item.otros_cargos_8_pagado))),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$filters.currency(item.otros_cargos_8_xpagar)))])]}}],null,false,3650745159)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }